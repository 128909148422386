.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #000;
  padding: 8px;
}
.container .left {
  display: flex;
  gap: 8px;
  align-items: center;
}
.container .right {
  display: flex;
  gap: 8px;
}
