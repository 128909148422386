.canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #eee;
}

.circle {
  position: absolute;
  border: 2px solid #1fff17;
  border-radius: 50%;
}
