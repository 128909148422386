.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100px;
  border: 2px solid #000;
}
.on,
.off {
  position: relative;
  width: 50%;
  padding-top: 50%;
}
.on {
  border: 2px solid red;
}
.tools {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.controls {
  display: flex;
}

.active,
.inactive {
  flex: 1;
  text-align: center;
  border: 2px solid #000;
}
.inactive {
  background-color: #ccc;
  pointer-events: none;
}
