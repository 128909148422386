.container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  z-index: 1;
}
.container * {
  pointer-events: auto;
}
.container .form {
  padding: 16px;
  background-color: #fff;
  border: 2px solid #000;
}
.form .title {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
.form .option {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}
.form .option .input {
  border: 2px solid #000;
  padding: 0 4px;
  text-align: center;
}
.form .create {
  width: 100%;
  padding: 8px;
  color: #fff;
  font-weight: bold;
  background-color: var(--color-blue-primary);
}

.download {
  padding: 16px;
  border: 2px solid #000;
  background-color: #fff;
}
.download .options {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}
.download .option {
  padding: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  background-color: var(--color-blue-primary);
}
.download .close {
  padding: 8px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  background-color: rgb(255, 64, 64);
}
