.container {
  flex: 2;
  width: 100%;
  overflow: auto;
}
.component {
  border: 2px solid #000;
  padding: 8px;
}
.component .label {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
}
.component .range {
  width: 100%;
}
input[type="range"] {
  -webkit-appearance: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  background-color: var(--color-blue-primary);
}
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #fff;
  border: 2px solid #000;
}
.opacity_number {
  width: 40px;
  text-align: center;
}
